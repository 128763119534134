<template>
  <v-container>
    <v-card class="px-7 py-9">
      <h1 class="mb-5 pl-3">{{ $t('components.conditionsOfPayment.title') }}</h1>
      <ul>
        <li class="my-2 pb-2">
          {{ $t('components.conditionsOfPayment.textOne') }}
        </li>
        <li class="my-2 pb-2">
          {{ $t('components.conditionsOfPayment.textTwo') }}
        </li>
        <li class="my-2 pb-2">
          {{ $t('components.conditionsOfPayment.textThree') }}
        </li>
      </ul>
    </v-card>
  </v-container>
</template>
<script>
export default {
  name: 'ConditionsOfPayment',
};
</script>
